import { MenuItemType } from '@paljs/ui/types'

const items: MenuItemType[] = [
  {
    title: 'Home',
    icon: { name: 'home' },
    link: { to: '/' },
  },
  {
    title: 'Invoice',
    icon: { name: 'clock-outline' },
    link: { to: '/invoice' },
  },
  {
    title: 'SECTIONS',
    group: true,
  },
  {
    title: 'Start Here',
    icon: { name: 'flash-outline' },
    link: { to: '/start-here' },
  },
  {
    title: 'Experiences',
    icon: { name: 'bulb-outline' },
    link: { to: '/posts' },
  },
  {
    title: 'Common Frameworks',
    icon: { name: 'book-outline' },
    link: { to: '/links' },
  },
  {
    title: 'Code Snippets',
    icon: { name: 'code-outline' },
    link: { to: '/files' },
  },
  {
    title: 'Buy Before Build',
    icon: { name: 'shopping-cart-outline' },
    link: { to: '/thirdparties' },
  },
]

export default items
