import React, { useState, useRef } from 'react'
import { ThemeProvider } from 'styled-components'

import { Layout, LayoutContent, LayoutContainer, LayoutColumns, LayoutColumn } from '@paljs/ui/Layout'
import icons from '@paljs/icons'
import { SidebarRefObject } from '@paljs/ui/Sidebar'

import themes from './themes'
import Header from './Header'
import SidebarCustom from './Sidebar'
import SimpleLayout from './SimpleLayout'

import SEO from '../components/SEO'

import useAuth from '../util/useAuth'

const LayoutPage: React.FC<{ isAuth?: boolean; seoTitle?: string }> = ({ children, isAuth = false, seoTitle = '' }) => {
  const { authState, setTheme } = useAuth()

  const [dir, setDir] = useState<'ltr' | 'rtl'>('ltr')
  const sidebarRef = useRef<SidebarRefObject>(null)

  const changeDir = () => {
    const newDir = dir === 'ltr' ? 'rtl' : 'ltr'
    setDir(newDir)
  }

  return (
    <ThemeProvider theme={themes(authState.theme, dir)}>
      <>
        {seoTitle && <SEO title={seoTitle} />}
        <SimpleLayout />
        <Layout evaIcons={icons} dir={dir} className={isAuth ? 'auth-layout' : ''}>
          {!isAuth && (
            <Header
              dir={dir}
              changeDir={changeDir}
              theme={{ set: setTheme, value: authState.theme }}
              toggleSidebar={() => sidebarRef.current?.toggle()}
            />
          )}
          <LayoutContainer>
            {!isAuth && <SidebarCustom ref={sidebarRef} />}
            <LayoutContent>
              <LayoutColumns>
                <LayoutColumn className='main-content'>{children}</LayoutColumn>
              </LayoutColumns>
              {/* {!isAuth && <LayoutFooter>Footer</LayoutFooter>} */}
            </LayoutContent>
          </LayoutContainer>
        </Layout>
      </>
    </ThemeProvider>
  )
}

export default LayoutPage
