import React from 'react'
import { Link } from 'gatsby'
import { Location } from '@reach/router'

import User from '@paljs/ui/User'
import ContextMenu from '@paljs/ui/ContextMenu'
import { LayoutHeader } from '@paljs/ui/Layout'
import { EvaIcon } from '@paljs/ui/Icon'
import { Actions } from '@paljs/ui/Actions'

import { HeaderStyle, Label, SelectStyled } from './styled'
import { getPathReady } from '../Sidebar'

import useAuth from '../../util/useAuth'
import { stringToColor } from '../../util/colors'
import { ThemeType } from '../themes'

interface HeaderProps {
  toggleSidebar: () => void
  theme: {
    set: (value: ThemeType) => void
    value: ThemeType
  }
  changeDir: () => void
  dir: 'rtl' | 'ltr'
}
const Header: React.FC<HeaderProps> = (props) => {
  const { authState } = useAuth()
  const nameToShow = authState.user?.fullName || 'No Name'
  const themeOptions = [
    {
      value: 'default',
      label: (
        <Label>
          <EvaIcon name='droplet' options={{ fill: '#a6c1ff' }} />
          Default
        </Label>
      ),
    },
    {
      value: 'dark',
      label: (
        <Label>
          <EvaIcon name='droplet' options={{ fill: '#192038' }} />
          Dark
        </Label>
      ),
    },
    {
      value: 'cosmic',
      label: (
        <Label>
          <EvaIcon name='droplet' options={{ fill: '#5a37b8' }} />
          Cosmic
        </Label>
      ),
    },
    {
      value: 'corporate',
      label: (
        <Label>
          <EvaIcon name='droplet' options={{ fill: '#3366ff' }} />
          Corporate
        </Label>
      ),
      selected: true,
    },
  ]
  return (
    <LayoutHeader fixed>
      <HeaderStyle>
        <Actions
          size='Medium'
          actions={[
            {
              icon: { name: 'menu-2-outline' },
              url: { onClick: props.toggleSidebar },
            },
            {
              content: (
                <Link to='/' className='logo'>
                  <img src='/icons/icon-48x48.png' width={40} />
                </Link>
              ),
            },
            {
              content: (
                <SelectStyled
                  isSearchable={false}
                  shape='SemiRound'
                  placeholder='Themes'
                  value={themeOptions.find((item) => item.value === props.theme.value)}
                  options={themeOptions}
                  onChange={({ value }: { value: ThemeType }) => props.theme.set(value)}
                />
              ),
            },
          ]}
        />
        <Actions
          size='Small'
          className='right'
          actions={[
            {
              content: (
                <Location>
                  {({ location }) => (
                    <ContextMenu
                      style={{ cursor: 'pointer' }}
                      placement='bottom'
                      currentPath={getPathReady(location.pathname)}
                      items={[
                        { title: 'Profile', link: { to: '/profile' } },
                        { title: 'Log out', link: { to: '/auth/logout' } },
                      ]}
                      Link={Link}>
                      <User color={stringToColor(nameToShow)} name={nameToShow} title={authState.user?.email} size='Medium' />
                    </ContextMenu>
                  )}
                </Location>
              ),
            },
          ]}
        />
      </HeaderStyle>
    </LayoutHeader>
  )
}
export default Header
