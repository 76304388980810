import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { Location } from '@reach/router'
import { Link } from 'gatsby'

import { SidebarBody, SidebarRefObject, SidebarProps, Sidebar } from '@paljs/ui/Sidebar'
import { Menu } from '@paljs/ui/Menu'

import menuItems from './menuItems'

export const getPathReady = (path: string): string => {
  return path.endsWith('/') ? path.slice(0, -1) : path
}

const SidebarCustom: React.ForwardRefRenderFunction<Omit<SidebarRefObject, 'hide'>, SidebarProps> = (props, ref) => {
  const sidebarRef = useRef<SidebarRefObject>(null)

  useImperativeHandle(ref, () => ({
    toggle() {
      sidebarRef.current?.toggle()
    },
  }))

  return (
    <Sidebar ref={sidebarRef} property='start' containerFixed responsive className='menu-sidebar'>
      <SidebarBody>
        <Location>
          {({ location }) => (
            <Menu
              className='sidebar-menu'
              Link={Link}
              items={menuItems}
              currentPath={getPathReady(location.pathname)}
              toggleSidebar={() => sidebarRef.current?.hide()}
            />
          )}
        </Location>
      </SidebarBody>
    </Sidebar>
  )
}

export default forwardRef(SidebarCustom)
