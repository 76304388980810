import styled from 'styled-components'

import Select from '@paljs/ui/Select'
import { breakpointDown } from '@paljs/ui/breakpoints'

const HeaderStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${breakpointDown('sm')`
    .right{
      display: none;
    }
  `}
  .right > div {
    height: auto;
    display: flex;
    align-content: center;
  }
  .logo {
    font-size: 1.25rem;
    white-space: nowrap;
    text-decoration: none;
  }
  .left {
    display: flex;
    align-items: center;
    .github {
      font-size: 18px;
      margin-right: 5px;
    }
  }
`

const Label = styled.span`
  display: flex;
  align-items: center;
`

const SelectStyled = styled(Select)`
  min-width: 150px;
`
export { HeaderStyle, Label, SelectStyled }
