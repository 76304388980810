import { createTheme } from '@paljs/theme'
import { DefaultTheme } from 'styled-components'

const shared: Partial<DefaultTheme> = {
  sidebarHeaderGap: '2rem',
  fontFamilyPrimary: `-apple-system,BlinkMacSystemFont,
          "Segoe UI",Roboto,"Helvetica Neue",
          Arial,sans-serif,"Apple Color Emoji",
          "Segoe UI Emoji","Segoe UI Symbol"`,
}

export type ThemeType = 'dark' | 'cosmic' | 'corporate' | 'default'

const themeService = (theme: ThemeType, dir: 'ltr' | 'rtl'): DefaultTheme => {
  return createTheme(theme, { dir, ...shared })
}

export default themeService
